<template>
    <div class="proyecto_upper" id="proyecto_upper">
      <b-container fluid class="full proyecto_container">
        <b-row no-gutters class="proyecto_wrap" align-h="between">
          <b-col lg="12" class="proyectoC_wrap">
            <h2 class="nombre_overlay" 
            v-html=" localeCode==='es' ? proyecto.nombre : proyecto.nombreIngles " />
            <b-carousel
              ref="projectCar"
              id="carousel-1"
              :interval="4000"
              style="text-shadow: 1px 1px 2px #333"
            >
              <!-- Text slides with image -->
              <b-carousel-slide
                v-for="(imagen, index) in proyecto.galeria"
                :key="index"
              >
                <template #img>
                  <img
                    class="d-block w-100"
                    width="1400"
                    height="830"
                    :src="imagen.url"
                    alt="image slot"
                  />
                </template>
              </b-carousel-slide>
            </b-carousel>
            <div class="nav_images d-flex d-lg-none">
              <b-img
                @click="prevSlide()"
                :src="require('@/assets/img/svg/ico-left-strong.svg')"
              ></b-img>
              <span>{{this.$t('common.photo')}}</span>
              <b-img
                @click="nextSlide()"
                :src="require('@/assets/img/svg/ico-right-strong.svg')"
              ></b-img>
            </div>
          </b-col>
          <b-col lg="4" class="proyectoC_wrap info_wrap">
            <div class="details_wrap">
              <b-img
                class="more d-none d-lg-block"
                :src="require('@/assets/img/svg/ico-plus-white.svg')"
              ></b-img>
              <b-img
                class="more d-block d-lg-none"
                :src="require('@/assets/img/svg/ico-plus.svg')"
              ></b-img>
              <p class="details">
                {{this.$t('common.state')}}:
                <em>{{ proyecto.ciudad }}.</em>
              </p>
  
              <p class="details">
                {{this.$t('common.country')}}
                <em>{{ proyecto.pais }}.</em>
              </p>
  
              <p class="details">
                {{this.$t('common.giro')}}
                <em>
                  {{ localeCode==='es' ? proyecto.giro : proyecto.giroIngles }}.
                </em>
              </p>
  
              <p class="details">
                {{this.$t('common.category')}}
                <em>{{ localeCode==='es' ? proyecto.categoriaName : proyecto.categoriaNameIngles }}.</em>
              </p>
            </div>
            <div class="description_wrap">
              <p v-html="localeCode==='es' ? proyecto.descripcion : proyecto.descripcionIngles " />
            </div>
            <div class="nav_images d-none d-lg-flex">
              <b-img
                @click="prevSlide()"
                :src="require('@/assets/img/svg/ico-prev.svg')"
              ></b-img>
              <span>{{this.$t('common.photo')}}</span>
              <b-img
                @click="nextSlide()"
                :src="require('@/assets/img/svg/ico-next.svg')"
              ></b-img>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </template>
  
  <script>
  
  import {mapGetters} from 'vuex'
import { EventBus } from '../../../main';
  
  export default {
    props: {
      proyecto: Object,
    },
    created(){

      EventBus.$on('change-section', () => {
        setTimeout(() => {
          this.scrollToProyectoUpper();
        }, 500);
      })
    },
    computed:{
      ...mapGetters({
        localeCode: "locale/localeCode",
      })
    },
    methods: {
      scrollToProyectoUpper() {
      
        const proyectoUpper = document.querySelector("#proyecto_upper");
        if (!proyectoUpper) return 
      
        proyectoUpper.scrollIntoView({
          behavior: "smooth",
        });

    },
      prevSlide() {
        this.$refs.projectCar.prev();
      },
      nextSlide() {
        this.$refs.projectCar.next();
      },
    },
  };
  </script>
  
  <style scoped>
  @import "./styles.css";
  </style>
  