<template>
    <div class="proyectos_upper">
      <b-container fluid class="proyectos_container">
        <b-row no-gutters class="proyectos_wrap" align-h="between">
          <b-col lg="2" class="proyecto_wrap">
            <h2 v-html="titulo"></h2>
          </b-col>
          <b-col lg="9" class="proyecto_wrap">
            <b-tabs content-class="mt-3" align="right">
              <b-tab
                v-for="categoria in categorias"
                @click="categoriaActiva = categoria.id"
                :key="categoria.id"
                :title="getTitle(categoria)"
                active
              >
                <VueSlickCarousel
                :key="categoriaActiva"
                  v-bind="settings"
                  v-if="proyectosFiltrados.length > 0"
                >
                  <CarouselPosts
                    v-for="proyecto in proyectosFiltrados"
                    :key="proyecto.id"
                    :proyecto="proyecto"
                    :tipo="tipo"
                  />
                </VueSlickCarousel>
                <p v-else v-html="$t('projects.empty')"></p>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
  
        <b-link class="mas-arrow big" 
        @click="router.push(tipo=='proyecto'?'proyectos' : 'propiedades')" />
      </b-container>
    </div>
  </template>
  
  <script>
  import VueSlickCarousel from "vue-slick-carousel";
  import router from "../../../router";
  // optional style for arrows & dots
  import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
  import CarouselPosts from "@/components/shared/CarouselPosts.vue";
  import { mapGetters } from "vuex";
  export default {
    name: "MyComponent",
    props: {
      proyectos: Array,
      tipo:{
        default:'proyecto',
      },
      titulo:{
        default:"",
      }
    },
    components: {
      CarouselPosts,
      VueSlickCarousel,
    },
    data() {
      return {
        router,
        settings: {
          dots: true,
          focusOnSelect: true,
          infinite: true,
          speed: 500,
          slidesToShow: 4,
          slidesToScroll: 1,
          touchThreshold: 5,
          responsive: [
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 576,
              settings: {
                slidesToShow: 1,
              },
            },
          ],
        },
        categoriaActiva: null,
      };
    },
    computed: {
      ...mapGetters({
        categoriasProyectos: "categorias/categoriasProyectos",
        categoriasPropiedades: "categorias/categoriasPropiedades",
        localeCode: "locale/localeCode",
      }),
      categorias(){
        if(this.tipo==='proyecto'){
          return this.categoriasProyectos
        }
        return this.categoriasPropiedades
      },
      proyectosFiltrados() {
        
        if (!this.categoriaActiva || this.categoriaActiva === "todos") {
          return this.proyectos;
        }
        return this.proyectos.filter((proyecto) => {
          return proyecto.categoria == this.categoriaActiva;
        });
      },
    },
    methods:{
      getTitle(categoria){
        if(this.localeCode==='es') return categoria.nombre?.toUpperCase()
        return categoria.nombreIngles?.toUpperCase()
      }
    }
  };
  </script>
  
  <style scoped>
  .proyectos_upper {
    padding-bottom: 30px;
  }
  .proyectos_container .proyectos_wrap h2 {
    font-size: 36.3px;
    font-weight: 400;
    line-height: 0.85;
    text-align: left;
    position: relative;
    padding-bottom: 5px;
    color: var(--texto);
  }
  .proyectos_container .proyectos_wrap h2 b {
    font-weight: 900;
  }
  .proyectos_container .proyectos_wrap h2:after {
    content: "";
    left: 0;
    bottom: 0;
    position: absolute;
    width: 50%;
    height: 3px;
    background: var(--primario);
  }
  .proyectos_container .mas-arrow.big {
    display: block;
    font-size: 16px;
    font-style: italic;
    line-height: 1.2;
    text-align: center;
    color: var(--oscuro);
    margin-top: 2rem;
  }
  .proyectos_container .proyectos_wrap >>> .nav-tabs {
    border-bottom: none;
  }
  .proyectos_container .proyectos_wrap >>> .nav-tabs a {
    font-size: 16px;
    line-height: 1.2;
    letter-spacing: -0.06px;
    color: var(--texto);
    background: var(--transparente);
    border: none;
    text-transform: uppercase;
    padding: 5px 10px;
    margin: 0 21px;
    border-bottom: 1px solid transparent;
  }
  .proyectos_container .proyectos_wrap >>> .nav-tabs .nav-item:last-child a {
    padding-right: 0;
    padding-left: 0;
    margin-right: 0;
  }
  .proyectos_container .proyectos_wrap >>> .nav-tabs a.active {
    border-bottom-color: var(--primario);
  }
  
  .proyecto_wrap >>> .slick-slide {
    margin: 0;
  }
  .proyecto_wrap >>> .proyecto_carousel {
    padding-top: 140%;
    position: relative;
  }
  .proyecto_wrap >>> .proyecto_carousel:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 105%;
    background: var(--overlay);
    z-index: 2;
  }
  .proyecto_wrap >>> .proyecto_carousel .proyecto_img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 105%;
    object-fit: cover;
  }
  .proyecto_wrap >>> .proyecto_carousel .detalle_wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 30px 28px;
    z-index: 2;
  }
  .proyecto_wrap >>> .proyecto_carousel .detalle_wrap h5 {
    font-size: 20.1px;
    font-weight: normal;
    line-height: 0.89;
    text-align: left;
    color: var(--blanco);
    width: 50%;
  }
  .proyecto_wrap >>> .proyecto_carousel .detalle_wrap hr {
    margin: 0;
    border-color: var(--blanco);
  }
  .proyecto_wrap >>> .proyecto_carousel .detalle_wrap a {
    font-size: 10.6px;
    font-weight: 300;
    font-style: italic;
    line-height: 1.2;
    text-align: left;
    color: var(--blanco);
  }
  .proyecto_wrap >>> .proyecto_carousel .detalle_wrap a img {
    filter: brightness(0) invert(1);
  }
  @media (max-width: 576px) {
    .proyectos_container .proyectos_wrap h2 {
      text-align: center;
      margin-bottom: 30px;
    }
    .proyectos_container .proyectos_wrap h2:after {
      left: 50%;
      transform: translateX(-50%);
    }
    .proyectos_container .proyectos_wrap >>> .nav-tabs {
      justify-content: center !important;
    }
    .proyectos_container .proyectos_wrap >>> .nav-tabs a {
      padding: 5px 15px !important;
    }
  }
  </style>
  