<template>
    <div class="project_section">
      <ProyectosTabs :proyectos="propiedades" 
      :titulo="this.$t('properties.title')"
      v-if="propiedades" tipo="propiedad" />
      <ProyectoDetails
        :proyecto="propiedadActivo"
        v-if="propiedades && propiedadActivo"
      />
      <b-overlay v-else :show="true" rounded="sm" />
      <Contacto />
    </div>
  </template>
  
  <script>
  import ProyectosTabs from "@/components/shared/ProyectosTabs/index.vue";
  import ProyectoDetails from "@/components/shared/ProyectoDetails/index.vue";
  import Contacto from "@/components/shared/Contacto.vue";
  import { mapActions, mapGetters } from "vuex";
  export default {
    components: {
      ProyectosTabs,
      ProyectoDetails,
      Contacto,
    },
    methods: {
      ...mapActions({
        getPropiedades: "propiedades/getPropiedades",
      }),
    },
    computed: {
      ...mapGetters({
        propiedades: "propiedades/propiedades",
        propiedadActivo: "propiedades/propiedadActivo",
      }),
    },
    async mounted() {
      await this.getPropiedades();
      this.$store.commit("propiedades/SET_PROPIEDAD_ACTIVO", this.propiedades[0].id);
    },
  };
  </script>
  
  <style scoped>
  @import "../assets/css/proyectos.css";
  </style>
  